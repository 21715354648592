/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

const mock = [
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/ClaimProcess1.jpeg',
    description:
      "FG Life understands that a claim incident can be daunting which is why we have implemented an internal in-depth claim process in order for us to effectively offer end-to-end assistance in your claim process. As part of our service to you as our clients, our dedicated claims team endeavors to assist and guide you in your claim process every step of the way. We handle your claim from start to finish, providing continuity, expertise, and advocating on your behalf with the providers. We'll not only save you time, but strive to make it as simple and hassle-free as possible for you.",
    desc: '',
    title: '',
    tags: [],
    author: {
      name: 'Clara Bertoletti',
      avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
    },
    date: '04 Aug',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/MakeClaim.jpeg',
    description:
      'Claim incident notification can be done either verbally by contacting our claims team over the phone in which case our friendly claims handler will discuss the claim incident with you and advise you of the next steps or alternatively a claim incident notification can be done via email to the claims team. A friendly claims team handler will then contact you and advise you of the next steps.',
    desc: '',
    title: 'Claim Incident Notification',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/FileClaim.jpeg',
    description:
      'A claim form will be sent to you with concise instructions and relevant requirements for completion as well as contact details for our claims team should you have any queries or require assistance in completing the claim form. Once you have returned the completed claim form back to our dedicated claims team, we will submit your claim to the relevant insurance provider. You have the option of submitting a claim directly through to the insurance provider skipping us the broker – FG Life. In this instance unless you or the insurance provider notifies our claims team of this action, there is no way for our claims team to follow up on nor will we be aware that a claim exists. Should we however be made aware, then the claims process will continue as stipulated in this document.',
    desc: '',
    title: 'Completion of Claim Form',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/ClaimSettlement.jpeg',
    description:
      'Settlement offers are advised towards the end of the claim process. This will generally include a breakdown of the offer. We will always enquire as to your acceptance of the settlement offer and we will advocate on your behalf with the insurance providers for the best possible outcome.',
    desc: '',
    title: 'Claim Settlements and Finalization',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/FollowUp.jpeg',
    description:
      'Claim follow ups are actioned throughout a particular claim’s lifespan. Follow ups will always include claim acknowledgement, claim reference number and claim status. Requirement requests, status update requests and or general updates are attended to as promptly as possible.',
    desc: '',
    title: 'Claim Follow Up',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
];

const PopularNews = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4} bgcolor={theme.palette.alternate.main}>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Disclaimer
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          The information provided and services described on this website is of
          a general nature and is not intended to be personalised financial
          advice on any particular situation. The information provided on this
          website is not intended to be a substitute for professional advice.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          You may seek appropriate personalised financial advice from a
          qualified financial adviser that suits your circumstances and goals.
          While every effort is made to ensure the information on this website
          is up-to-date and correct, AIFP Personal Risk Limited
          (FG Life) makes no representations or warranties of any kind, express or
          implied, about the completeness, accuracy, reliability, suitability or
          availability with respect to the website or the information, products,
          services, or related graphics contained on the website for any
          purpose. Any reliance you place on such information is therefore
          strictly at your own risk. The information on this website is subject
          to change at any time.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          The information on this website is subject to change without notice
          and we do not guarantee the accuracy, completeness, or reliability of
          the information provided. The calculators or other interactive tools
          which may be used on this website from time to time are intended to be
          illustrative only. FG Life does not represent or warrant the accuracy,
          adequacy or completeness of any information provided by those
          calculators or other tools.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          FG Life and its director/s, employees, associates and contractors do not
          accept any liability arising in any way (including by reason of
          negligence) for errors in, or omissions from, the information and do
          not accept any loss or damage (including, without limitation loss of
          profits, business or data), however caused, which arise out of, or
          result from any reliance on, or inability to use or access, any
          information on the website or any other website which is linked to
          this website. This website may contain links to third-party websites.
          We are not responsible for the content or accuracy of those websites,
          and the inclusion of these links does not imply endorsement of those
          websites or their services.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          All materials on this website are protected by copyright and
          intellectual property laws and are the property of FG Life unless
          otherwise explicitly stated. The website may also contain a number of
          trademarks, logos and symbols which are either owned by FG Life or used
          with the approval of the respective trademark owner. Nothing on the
          website should be construed as granting any licence to use any
          trademark without the permission of the trademark owner.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          Every effort is made to keep the website up and running smoothly.
          However, FG Life takes no responsibility for, and will not be liable for,
          any loss you may suffer due to the website being temporarily
          unavailable either during its planned maintenance or due to technical
          or other issues beyond our control.
        </Typography>
      </Box>
    </Box>
  );
};

export default PopularNews;
